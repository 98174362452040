import { useEffect, useRef, useState } from "react";

import CircleIcon from '@mui/icons-material/Circle';
import axios from "axios";
import { useEnvironmentContext } from "./Contexts/EnvironmentProvider";
import { useAuthContext } from "./Contexts/AuthProvider";
import { useTheme } from "@mui/material";

interface PollResult {
  count: number
}

const NewNotificationIndicator = () => {
  const { user } = useAuthContext();
  const [hasNewNotification, setHasNewNotification] = useState(false);

  const theme = useTheme();
  const { lastSeenNotification } = useEnvironmentContext();
  const lastPolledId = useRef(-1);

  const fetchData = () => {
    if (hasNewNotification && lastPolledId.current === lastSeenNotification) return;

    if (!user) return;

    axios.get<PollResult>(`/api/notifications/new`, { params: { after: lastSeenNotification } })
      .then(result => {
        setHasNewNotification(result.data.count > 0);
        lastPolledId.current = lastSeenNotification;
      });
  };

  useEffect(() => {
    lastPolledId.current = -1;
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [lastSeenNotification]);

  useEffect(() => {
    const interval = setInterval(() => fetchData(), 30000);

    return () => clearInterval(interval);
  }, [hasNewNotification, lastSeenNotification]);

  if (!hasNewNotification) {
    return null;
  }

  return (
    <CircleIcon sx={{ fontSize: '8px', color: theme.palette.theme.main, right: '8px', top: '2px', position: 'absolute' }} />
  );
}

export default NewNotificationIndicator;
