import { useEffect, useState } from 'react'
import axios from 'axios'

import { useParams, useNavigate } from "react-router-dom";

import { useAuthContext } from "../Contexts/AuthProvider";

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import LoginPanel from '../Login/LoginPanel';
import useNavigateBack from '../Hooks/useNavigateBack';
import useNavigateRedirect from '../Hooks/useNavigateRedirect';
import Session from '../interfaces/Session';
import useActivityLogger from '../Hooks/useActivityLogger';

const SessionRouter = () => {
  const { linkId } = useParams();

  const [hasError, setHasError] = useState(false);
  const [loggingIn, setLoggingIn] = useState(false);

  const redirect = useNavigateRedirect();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const postActivity = useActivityLogger();

  const { user } = useAuthContext();

  useEffect(() => {
    axios
      .get<Session>(`/api/sharelinks/${linkId}/session`)
      .then(res => {
        console.log(res.data, 'axios')

        const sessionId = res.data.id;

        if (sessionId) {
          const scriptId = res.data.scriptId;
          postActivity('open_sharelink', { id: linkId, script: scriptId });

          redirect(`/session/${sessionId}`);
        }
        else navigateBack();
      })
      .catch(error => {
        setHasError(true);
      })
  }, [])

  if (hasError) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'absolute', top: '0px', left: '0px', right: '0px', bottom: '0px'
        }}>

        <Stack
          direction='row'
          sx={{ backgroundColor: 'black', width: '100%' }}>
          <IconButton sx={{ color: 'white' }} onClick={navigateBack}>
            <ArrowBackIcon />
          </IconButton>
        </Stack>
        {user ?
          <p>This link is invalid.</p>
          :
          <>
            <p>Please log in to access the shared script.</p>
            <Button variant="contained"
              onClick={() => setLoggingIn(true)}
              sx={{ backgroundColor: 'black', textTransform: 'none' }}>
              Log in
            </Button>
          </>
        }

        {
          loggingIn &&
          <LoginPanel initialState='login' onAuthenticate={() => navigate(0)} onClose={() => setLoggingIn(false)} />
        }
      </Box>
    );
  } else return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
    </Box>
  );
}

export default SessionRouter;
